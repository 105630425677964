import "./ContactPage.scss";
import { MdOutlineHomeWork } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../component/textError/TextError";
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion";
import axios from "axios";
import { baseUrl, contact } from "../../utils/apiData";
import { useState } from "react";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { RiWhatsappFill } from "react-icons/ri";
import { images } from "../../utils/images";
import { social } from "../../utils/social";

const ContactPage = ({ homePage, setContact, locations }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  

  // contact Api
  const contactApi = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(`${baseUrl}/${contact}`, { ...data });
      if (res?.data?.success) {
        setSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const initialValues = {
    name: "",
    phone: "",
    email: "",
    location: "",
    gender: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Field is required!"),
    phone: Yup.string().required("Field is required!"),
    email: Yup.string()
      .email("Invalid email formate!")
      .required("Email is required!"),
    location: Yup.string().required("Field is required!"),
    gender: Yup.string().required("Field is required!"),
  });

  const onSubmit = (values, { resetForm }) => {
    contactApi(values);
    resetForm();
  };
  return (
    <motion.section
      initial={{ opacity: 0.32 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      className="contact_page"
    >
      <motion.h1
        initial={{ translateY: -48 }}
        whileInView={{ translateY: 0 }}
        transition={{ duration: 0.6, ease: "easeIn" }}
        className="extra_large"
      >
        BOOK
      </motion.h1>
      <motion.h1
        initial={{ translateY: 48 }}
        whileInView={{ translateY: 0 }}
        transition={{ duration: 0.6, ease: "easeIn" }}
        className="with_us"
      >
        With&nbsp;Us
      </motion.h1>

      <button className="close_btn" onClick={() => setContact(false)}>
        <IoClose />
      </button>
      <div className="left">
        <div className="filler"></div>
        <div className="feature">
          <span>
            <MdOutlineHomeWork />
          </span>
          <p>{homePage?.contact_title_1 || "-"}</p>
        </div>
        <div className="feature">
          <span>
            <MdOutlineHomeWork />
          </span>
          <p>{homePage?.contact_title_2 || "-"}</p>
        </div>
        <div className="feature">
          <span>
            <MdOutlineHomeWork />
          </span>
          <p>{homePage?.contact_title_3 || "-"}</p>
        </div>

        <div className="feature">
          <span>
            <MdOutlineHomeWork />
          </span>
          <p>{homePage?.contact_title_4 || "-"}</p>
        </div>
      </div>
      <div className="right">
        <div className="left_des">
          <img src={images.logo} alt="Brand Logo" />
          <p>{homePage?.contact_description || "-"}</p>
          <div className="social">
            <a href="https://www.instagram.com/livinout.in/" target="_blank">
              <RiInstagramFill /> Instargram
            </a>
            <a href="https://www.linkedin.com/livinout/" target="_blank">
              <FaLinkedinIn /> Linkedin
            </a>
            <a href="https://www.facebook.com/livinout.in/" target="_blank">
              <FaFacebookF /> Facebook
            </a>
            <a href={`mailto:${social.email}`} target="_blank">
              <MdEmail /> {social.email}
            </a>

            <a href={`tel:${social.mobile}`} target="_blank">
              <IoCall />
              {social.mobile}
            </a>
            <a href={`https://wa.me/${social.whatsapp}`} target="_blank">
              <RiWhatsappFill /> What's App
            </a>
          </div>
        </div>
        <div className="form_wrap">
          <h3>
            <b>Join the Livinout Community!</b>
          </h3>

          {success ? (
            <div className="success_message">
              <h3>
                <b>Thanks!</b>
              </h3>
              <p className="mt-3">
                Thanks for filling out the form. Welcome to the Livinout
                community! We’re excited to have you with us.
              </p>
              <button
                type="button"
                className="success_close"
                onClick={() => setSuccess(false)}
              >
                <IoClose />
              </button>
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(props) => (
                <Form>
                  {/* Name */}
                  <div
                    className={`input_wrap ${
                      props?.values?.name ? "active" : ""
                    }`}
                  >
                    <label>Name</label>
                    <Field type="text" id="name" name="name" />
                  </div>
                  <ErrorMessage name="name" component={TextError} />

                  {/* Phone */}
                  <div
                    className={`input_wrap ${
                      props?.values?.phone ? "active" : ""
                    }`}
                  >
                    <label>Phone</label>
                    <Field type="number" id="phone" name="phone" />
                  </div>
                  <ErrorMessage name="phone" component={TextError} />

                  {/* Email */}
                  <div
                    className={`input_wrap ${
                      props?.values?.email ? "active" : ""
                    }`}
                  >
                    <label>Email</label>
                    <Field type="email" id="email" name="email" />
                  </div>
                  <ErrorMessage name="email" component={TextError} />

                  {/* location */}
                  <div className="input_wrap">
                    <Field as="select" id="location" name="location">
                      <option value="" default disabled>
                        - Select Location -
                      </option>
                      {locations?.map((data) => {
                        return (
                          <option value={data?.location}>
                            {data?.location}
                          </option>
                        );
                      })}
                    </Field>
                  </div>

                  {/* gender */}
                  <div className="input_wrap">
                    <Field as="select" id="gender" name="gender">
                      <option value="" default disabled>
                        - Select Gender -
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </Field>
                  </div>

                  <ErrorMessage name="gender" component={TextError} />
                  {loading ? (
                    <button type="button">Loading...</button>
                  ) : (
                    <button type="submit">Submit</button>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </motion.section>
  );
};

export default ContactPage;
