import "./Counters.scss";
import Counter from "../../../component/counter/Counter";
import { motion } from "framer-motion";
import { IoMdAdd } from "react-icons/io";

const Counters = ({homePage}) => {
  const counterData = [
    {
      id: 0,
      name:homePage?.card_1_title || "-",
      count:+homePage?.card_1_count || +"0",
    },
    {
      id: 1,
      name:homePage?.card_2_title || "-",
      count:+homePage?.card_2_count || +"0",
    },
    {
      id: 2,
      name:homePage?.card_3_title || "-",
      count:+homePage?.card_3_count || +"0",
    },
    {
      id: 3,
      name:homePage?.card_4_title || "-",
      count:+homePage?.card_4_count || +"0",
    },
  ];

  return (
    <section className="counters_sec">
      <div className="content_wrap  p_t p_b">
        <div className="counter_grid">
          {counterData.map((data, j) => {
            return (
              <motion.div
                initial={{scale:0.5, opacity: 0.4 }}
                whileInView={{scale: 1, opacity: 1 }}
                transition={{
                  duration: 0.5,
                  delay: `${0.1 * j}`,
                  ease: "easeIn",
                }}
                className="counter_card"
                key={data.id}
              >
                <h1>
               <Counter count={Number.parseInt(data?.count)} />
                </h1>
                <p>{data?.name}</p>
              </motion.div>
            );
          })}
        </div>
      
      </div>
    </section>
  );
};

export default Counters;
